var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "90px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "员工", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "140px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "user_name", $$v)
                  },
                  expression: "form.user_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "日期", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  disabled: "",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.prop == "bili"
                          ? _c("div", [
                              _c("div", [
                                _vm._v(
                                  "销售" + _vm._s(row.sale_money_condition)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "退货" + _vm._s(row.back_money_condition)
                                ),
                              ]),
                            ])
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "",
                label: "提成(元)",
                width: "",
                align: "center",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "total_money",
                  label: "合计提成",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sale_money",
                  label: "销售提成",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "back_money",
                  label: "退货提成",
                  width: "",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "", label: "数量", width: "", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goods_total_num",
                  label: "净销售量",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_sale_num",
                  label: "销售数量",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_back_num",
                  label: "退货数量",
                  width: "",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "",
                label: "金额(元)",
                width: "",
                align: "center",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goods_total_money",
                  label: "销售净额",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_sale_money",
                  label: "销售金额",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_back_money",
                  label: "退货金额",
                  width: "",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "",
                label: "利润(元)",
                width: "",
                align: "center",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goods_total_rate",
                  label: "净利润",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_sale_rate",
                  label: "销售利润",
                  width: "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_back_rate",
                  label: "退货利润",
                  width: "",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }