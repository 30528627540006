<template>
  <!-- NAME[epic=绩效] 提成明细表 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="90px"
      label-suffix=":"
    >
      <el-form-item label="员工" prop="">
        <el-input
          v-model="form.user_name"
          disabled
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="日期" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          disabled
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <!-- <el-form-item label="商品类别" prop=""></el-form-item>
      <el-form-item label="商品名称" prop="">
        <el-input v-model="form.goods_name"></el-input>
      </el-form-item> -->
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.prop == 'bili'">
            <div>销售{{ row.sale_money_condition }}</div>
            <div>退货{{ row.back_money_condition }}</div>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <!-- 提成 -->
      <el-table-column prop="" label="提成(元)" width="" align="center">
        <el-table-column
          prop="total_money"
          label="合计提成"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sale_money"
          label="销售提成"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="back_money"
          label="退货提成"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
      <!-- 数量 -->
      <el-table-column prop="" label="数量" width="" align="center">
        <el-table-column
          prop="goods_total_num"
          label="净销售量"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_sale_num"
          label="销售数量"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_back_num"
          label="退货数量"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
      <!-- 金额 -->
      <el-table-column prop="" label="金额(元)" width="" align="center">
        <el-table-column
          prop="goods_total_money"
          label="销售净额"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_sale_money"
          label="销售金额"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_back_money"
          label="退货金额"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
      <!-- 利润 -->
      <el-table-column prop="" label="利润(元)" width="" align="center">
        <el-table-column
          prop="goods_total_rate"
          label="净利润"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_sale_rate"
          label="销售利润"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_back_rate"
          label="退货利润"
          width=""
          align="center"
        ></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'CommissionDetail',
    components: {},
    beforeRouteEnter(to, from, next) {
      next((v) => {
        v.form.goods_id = to.query.goods_id
        v.form.goods_name = to.query.goods_name
        v.form.type = to.query.type
        v.form.user_name = to.query.user_name
        v.form.start_time = to.query.start_time
        v.form.end_time = to.query.end_time
        v.form.user_id = to.query.user_id
        v.fetchData()
      })
    },
    data() {
      return {
        form: {
          goods_id: '', //商品id
          goods_name: '', //商品id
          type: '', //类型1业务提成2送货提成3调拨提成
          start_time: '', //开始时间
          end_time: '', //结束时间
          user_id: '', //员工id
          user_name: '',
        },
        loading: false,
        list: [],
        total: 0,
        columns: [
          {
            prop: 'goods_name',
            label: '商品',
            width: '140',
          },
          {
            prop: 'specs',
            label: '规格',
            width: '140',
          },
          // {
          //   prop: 'specs',
          //   label: '规格',
          //   width: '140',
          // },
          {
            prop: 'way_text',
            label: '提成方式',
            width: '120',
          },
          {
            prop: 'between',
            label: '提成分段',
            width: '120',
          },
          {
            prop: 'bili',
            label: '比例/金额',
            width: '120',
          },
        ],
      }
    },
    computed: {
      time() {
        return [this.form.start_time, this.form.end_time]
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/taskAdmin/extract-info-detail/form-info',
          this.form
        )
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/extract-info-detail/form-info-export',
          '提成明细表.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
